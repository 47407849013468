import ReactSlider, { ReactSliderProps } from 'react-slider';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import { FC, ReactNode } from 'react';

interface SliderProps extends ReactSliderProps {
  disabled?: boolean;
  error?: string | null;
  className?: string;
  label?: ReactNode;
  dataCy?: string;
}

const Slider: FC<SliderProps> = ({
  disabled = false,
  renderThumb,
  error,
  className,
  label,
  dataCy,
  ...rest
}) => {
  return (
    <FormGroupWrap
      className={`mb-0 w-100p ${className || ''}`}
      label={label}
      error={error}
      dataCy={dataCy}
    >
      <ReactSlider
        className="slider-input"
        thumbClassName="slider-input--thumb"
        trackClassName="slider-input--track"
        renderThumb={(props, state) =>
          renderThumb ? renderThumb(props, state) : <div {...props}>{state.valueNow}</div>
        }
        disabled={disabled}
        {...rest}
      />
    </FormGroupWrap>
  );
};

export default Slider;
