import BasicNumberField, { BasicNumberFieldProps } from './BasicNumberField';
import { FC } from 'react';
import { useAppSelector } from '@/store/hooks';
import {
  selectIsItemInitial,
  selectOnBoardStatus,
  selectActiveRequisitionID
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import { reviewProcessHasSomeCompletedSteps } from '@/common/components/review-process/store/selectors-ts';

const RequestedQuantity: FC<BasicNumberFieldProps> = ({ itemID, disabled, ...rest }) => {
  const isInitial = useAppSelector(state => selectIsItemInitial(state, itemID));
  const activeId = useAppSelector(selectActiveRequisitionID);

  const isLockedAfterReviewProcess = useAppSelector(state =>
    reviewProcessHasSomeCompletedSteps(state, activeId)
  );

  const onBoardStatus = useAppSelector(selectOnBoardStatus);

  return (
    <BasicNumberField
      itemID={itemID}
      disabled={
        disabled || (onBoardStatus === 'submitted' && (isLockedAfterReviewProcess || isInitial))
      }
      {...rest}
    />
  );
};

export default RequestedQuantity;
