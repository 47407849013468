import TYPES from './types';
import _pickBy from 'lodash/pickBy';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

import { createSupplierHistory, shouldRemoveCategoryItem, supplierStatuses } from './helpers';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  createSuppliersWithEquivalentCurrency,
  updateSuppliersWithEquivalentCurrency
} from '../categories/items/helpers';
import paths from 'routing/routes/_paths';
import { unlinkFinding } from 'common/components/purchasing/requisition/store/actions';

const INITIAL_STATE = {
  active: null, // active requisition
  categories: [], // [categoryID1, categoryID2]
  categoryData: {}, // { categoryID1: { ... }, categoryID2: { ... } }
  categoryItems: {}, // { categoryID1: [itemID1, itemID2], categoryID2: [itemID3] }
  items: {}, // { itemID1: { ... }, itemID2: { ... }, 'unlisted_description_1': { ... } }
  suppliers: [], // [supplierRequisitionID1, supplierRequisitionID2, supplierRequisitionID3]
  visibleSuppliers: [], // [supplierRequisitionID1, supplierRequisitionID2, supplierRequisitionID3]
  visibleItemFields: { listed: [], unlisted: [] },
  suppliersWithEquivalentCurrency: [],
  suppliersData: {}, // { supplierRequisitionID1: {...}, supplierRequisitionID2: {...} }
  suppliersHistory: {}, // { supplierRequisitionID1: [], supplierRequisitionID2: [] }
  suppliersEvaluations: {}, // { supplierRequisitionID1: [], supplierRequisitionID2: [] }
  requisitionEmails: [],
  requisitionLinkedEmails: [],
  onBoardStatus: 'draft',
  isAutoSaving: false,
  isAddingSupplier: false,
  isEligibleForPendingReview: false,
  totals: null,
  isPurchasingItemsSelectionDrawerOpen: false,
  isCommunicationDrawerOpen: false,
  isPmsItemsSelectionDrawerOpen: false,
  activeCategoryID: null,
  activeTableConfiguration: { id: 'default', settings: [], name: '' },
  itemsTableConfiguration: [],
  itemsTableComparisonViewEnabled: false,
  requisitionVessel: null,
  requisitionCompany: null,
  isForVessel: false,
  lastDeliveredTooltip: { itemId: null, isOpen: false },
  lastDeliveredTotals: [],
  preventLocationChange: true,
  isTemplate: false,
  isLoadedFromPastRequisition: false,
  showRemarksAndAttachments: false,
  collapsedFindings: { mode: 'requisition', collapsed: true },
  isSummaryViewActive: false,
  searchingEmailLink: null,
  itemCommentsDrawer: { isOpen: null, itemID: null, requisitionItemID: null },
  isSupplierPoDetailsDrawerOpen: false,
  selectedItemToBeReplaced: null
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.RESET_STATE:
      return {
        ...INITIAL_STATE,
        categories: [],
        categoryData: {},
        categoryItems: {},
        items: {},
        suppliers: [],
        suppliersData: {},
        suppliersHistory: {},
        suppliersEvaluations: {},
        totals: null,
        requisitionVessel: null,
        requisitionCompany: null,
        isForVessel: false,
        itemsTableComparisonViewEnabled: false
      };

    case TYPES.RESET_TEMPLATE_STATE: {
      return {
        ...state,
        categories: [],
        categoryData: {},
        categoryItems: {},
        items: {},
        isTemplate: false,
        itemsTableComparisonViewEnabled: false
      };
    }

    case `${TYPES.GET_PURCHASING_REQUISITION_EMAIL_LINK}/pending`:
      return {
        ...state,
        searchingEmailLink: null
      };

    case `${TYPES.GET_PURCHASING_REQUISITION_EMAIL_LINK}/fulfilled`:
      return {
        ...state,
        searchingEmailLink: payload?.url
      };

    case TYPES.GET_PURCHASING_REQUISITIONS_TOTALS.SUCCESS:
      return {
        ...state,
        totals: payload
      };

    case TYPES.SET_CATEGORY:
      return {
        ...state,
        categories: !state.categories.length // Add first category
          ? [payload.data.id]
          : state.categories.includes('') // Append new category
          ? _map(state.categories, c => (c === '' ? payload.data.id : c))
          : payload.index !== null // Replace existing category
          ? _map(state.categories, (c, index) => (index === payload.index ? payload.data.id : c))
          : state.categories,
        categoryData: { ...state.categoryData, [payload.data.id]: payload.data }
      };

    case TYPES.ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, payload]
      };

    case TYPES.REMOVE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter((_, index) => index !== payload)
      };

    case TYPES.ADD_CATEGORY_ITEM:
      const itemKey = `${payload.item?.entity_type ? `${payload.item?.entity_type}_` : ''}${
        payload.item?.id
      }`;

      return {
        ...state,
        items: {
          ...state.items,
          [itemKey]: {
            ...payload.item,
            requested_packaging_id: _get(payload, 'item.purchasing_unit.id', null)
          }
        },
        categoryItems: {
          ...state.categoryItems,
          [payload.categoryID]: [...(state.categoryItems[payload.categoryID] || []), itemKey]
        }
      };

    case TYPES.REMOVE_CATEGORY_ITEM:
      return {
        ...state,
        items: _pickBy(state.items, (_, key) => key !== payload.itemID),
        categoryItems: {
          ...state.categoryItems,
          [payload.categoryID]: _filter(
            state.categoryItems[payload.categoryID],
            id => id !== payload.itemID
          )
        }
      };

    case `${TYPES.REMOVE_PMS_ITEMS}/fulfilled`:
      return {
        ...state,
        categoryItems: Object.keys(state.categoryItems).reduce((prev, categoryID) => {
          prev[categoryID] = state.categoryItems[categoryID].filter(
            id => !shouldRemoveCategoryItem(id, payload)
          );

          return prev;
        }, {}),
        items: Object.keys(state.items).reduce((prev, curr) => {
          if (!shouldRemoveCategoryItem(curr, payload)) {
            prev[curr] = state.items[curr];
          }
          return prev;
        }, {})
      };

    case TYPES.SET_ITEM_FIELD:
      let updatedItem = { ...state.items[payload.itemID] };

      if (payload.supplierRequisitionID) {
        updatedItem.supplier_details = {
          ...updatedItem.supplier_details,
          [payload.supplierRequisitionID]: {
            ...updatedItem.supplier_details[payload.supplierRequisitionID],
            ...payload.field
          }
        };
      } else {
        updatedItem = { ...updatedItem, ...payload.field };
      }

      return {
        ...state,
        items: { ...state.items, [payload.itemID]: updatedItem }
      };

    case TYPES.IS_ELIGIBLE_FOR_PENDING_REVIEW.SUCCESS:
      return {
        ...state,
        isEligibleForPendingReview: payload?.is_eligible
      };

    case TYPES.GET_PURCHASING_REQUISITION.START:
      return { ...state, active: state.active?.id === payload?.params?.id ? state.active : null };

    case TYPES.GET_PURCHASING_REQUISITION.ERROR:
      return { ...state, active: null };

    case TYPES.GET_PURCHASING_REQUISITION.SUCCESS: {
      const { categories, onboard_status, load_from_requisition, ...rest } = payload;

      const requisitionCategoriesState = _orderBy(categories, ['code'], ['asc']).reduce(
        (acc, cur) => {
          acc.categories.push(cur.id);
          acc.categoryData[cur.id] = cur;

          return acc;
        },
        { categories: [], categoryData: {} }
      );

      return {
        ...state,
        ...requisitionCategoriesState,
        onBoardStatus: load_from_requisition ? 'draft' : onboard_status || 'draft',
        active: load_from_requisition ? null : rest,
        requisitionVessel: rest.vessel,
        requisitionCompany: rest.company,
        isForVessel: rest.is_for_vessel,
        isTemplate: rest.is_template
      };
    }

    case TYPES.UPDATE_PURCHASING_REQUISITION.START:
      return { ...state, isAutoSaving: payload?.params?.isAutoSaving };

    case TYPES.UPDATE_PURCHASING_REQUISITION.SUCCESS:
      return { ...state, active: { ...state.active, ...payload }, isAutoSaving: false };

    case TYPES.GET_PURCHASING_REQUISITION_ITEMS.SUCCESS: {
      const requisitionItemsState = payload.data.reduce(
        (acc, cur) => {
          const {
            id,
            item,
            comments,
            file,
            requested_packaging_id,
            requested_quantity,
            revised_quantity,
            rob,
            proposed_quantity,
            total_proposed_quantity,
            total_approved_quantity,
            supplier_details,
            unlisted_description,
            is_initial,
            item_type,
            created_at,
            replaced_item,
            replaced_item_id,
            replaced_at
          } = cur;

          const itemKey = cur.item_id ? `${item_type}_${cur.item_id}` : unlisted_description;

          const last_delivered = state.items[itemKey]?.last_delivered;
          const last_delivered_quantity = state.items[itemKey]?.last_delivered_quantity;
          const isOnBoard = payload.params?.isOnBoard;

          if (!acc.categoryItems[cur.category_id]) acc.categoryItems[cur.category_id] = [];
          acc.categoryItems[cur.category_id].push(itemKey);

          acc.items[itemKey] = {
            last_delivered,
            last_delivered_quantity,
            requisition_item_id: id, // use in update/delete requests
            code: item?.code || item?.part_no,
            id: cur.item_id || unlisted_description,
            description: item?.description,
            unlisted_description,
            comments: isOnBoard ? comments?.text : comments,
            file,
            requested_packaging_id,
            requested_quantity,
            revised_quantity,
            rob,
            proposed_quantity,
            total_proposed_quantity,
            total_approved_quantity,
            is_out_of_the_list: unlisted_description ? true : false,
            is_initial,
            item_type,
            vessel_systems: item?.vessel_systems || null,
            drawing_no: item?.drawing_no,
            color_hex_code: item?.color_hex_code,
            color_name: item?.color_name,
            created_at,
            replaced_item,
            replaced_item_id,
            replaced_at,
            spare_part_comments: item_type === 'spare_part' ? item?.comments : null
          };

          if (supplier_details) {
            acc.items[itemKey].supplier_details = supplier_details
              .filter(detail =>
                payload.params.requisition_supplier_id
                  ? payload.params.requisition_supplier_id == detail.requisition_supplier_id
                  : detail.requisition_supplier_id
              )
              .reduce((acc, detail) => {
                acc[detail.requisition_supplier_id] = { ...detail };

                return acc;
              }, {});

            if (
              payload.params.requisition_supplier_id &&
              state.items?.[itemKey]?.supplier_details &&
              Object.keys(state.items?.[itemKey]?.supplier_details)?.length > 1
            ) {
              // Do not reset the supplier_details of any other supplier
              acc.items[itemKey].supplier_details = {
                ...state.items?.[itemKey]?.supplier_details,
                ...acc.items[itemKey].supplier_details
              };
            }
          }

          return acc;
        },
        {
          categoryItems: {},
          items: {}
        }
      );

      return {
        ...state,
        ...requisitionItemsState
      };
    }

    case TYPES.GET_PURCHASING_REQUISITIONS_LAST_DELIVERED_ITEMS.SUCCESS:
      if (payload) {
        const updatedItems = { ...state.items };

        if (payload?.data?.length) {
          payload.data.forEach(item => {
            if (updatedItems[`${item.item_type}_${item.item_id}`]) {
              updatedItems[`${item.item_type}_${item.item_id}`].last_delivered_quantity =
                item?.supplier_detail?.delivered_quantity;

              updatedItems[`${item.item_type}_${item.item_id}`].last_delivered = payload?.data
                .filter(
                  last_delivered =>
                    last_delivered.item_id === item.item_id &&
                    last_delivered.item_type === item.item_type
                )
                .map(last_delivered => ({
                  item: last_delivered?.item || {},
                  supplier_detail: last_delivered?.supplier_detail || {},
                  company: last_delivered?.requisition?.company || {},
                  vessel: last_delivered?.requisition?.vessel || {},
                  requisition_code: last_delivered?.requisition?.code || null,
                  requisition_id: last_delivered?.requisition?.id || null
                }));
            }
          });
        }

        return {
          ...state,
          items: updatedItems,
          lastDeliveredTotals: payload?.totals
        };
      } else {
        return state;
      }

    case TYPES.UPLOAD_PURCHASING_REQUISITION_SUPPLIER_FILE.START:
    case TYPES.SEND_PURCHASING_REQUISITION_SUPPLIER_DELIVERY_REPORT.START:
    case TYPES.UPDATE_PURCHASING_REQUISITION_ITEM.START:
    case TYPES.ADD_PURCHASING_REQUISITION_SUPPLIER.START:
    case TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER.START:
      return { ...state, isAutoSaving: true };

    case TYPES.UPLOAD_PURCHASING_REQUISITION_SUPPLIER_FILE.SUCCESS:
    case TYPES.UPLOAD_PURCHASING_REQUISITION_SUPPLIER_FILE.ERROR:
    case TYPES.SEND_PURCHASING_REQUISITION_SUPPLIER_DELIVERY_REPORT.SUCCESS:
    case TYPES.SEND_PURCHASING_REQUISITION_SUPPLIER_DELIVERY_REPORT.ERROR:
    case TYPES.UPDATE_PURCHASING_REQUISITION_ITEM.SUCCESS:
    case TYPES.UPDATE_PURCHASING_REQUISITION_ITEM.ERROR:
    case TYPES.ADD_PURCHASING_REQUISITION_SUPPLIER.SUCCESS:
    case TYPES.ADD_PURCHASING_REQUISITION_SUPPLIER.ERROR:
    case TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER.ERROR:
      return { ...state, isAutoSaving: false };

    case TYPES.EVALUATE_PURCHASING_REQUISITION_SUPPLIER.SUCCESS:
    case TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER.SUCCESS:
      return {
        ...state,
        suppliersData: {
          ...state.suppliersData,
          [payload.id]: {
            ...state.suppliersData[payload.id],
            ...payload
          }
        },
        suppliersEvaluations: payload.evaluation
          ? { ...state.suppliersEvaluations, [payload.id]: payload.evaluation }
          : state.suppliersEvaluations,
        isAutoSaving: false
      };

    case TYPES.SUBMIT_PURCHASING_REQUISITION.SUCCESS:
      return {
        ...state,
        onBoardStatus: payload.onboard_status,
        active: state.active
          ? { ...state.active, revised_correction_comments: payload.revised_correction_comments }
          : state.active
      };

    case TYPES.SET_IS_ADDING_SUPPLIER:
      return {
        ...state,
        isAddingSupplier: payload
      };

    case TYPES.GET_PURCHASING_REQUISITION_SUPPLIERS.SUCCESS:
    case TYPES.SET_PURCHASING_REQUISITION_SUPPLIERS: {
      const suppliersState = payload.reduce(
        (acc, { supplier, history, evaluation, ...requisitionSupplier }) => {
          acc.suppliers.push(requisitionSupplier.id);
          updateSuppliersWithEquivalentCurrency(
            requisitionSupplier,
            acc.suppliersWithEquivalentCurrency
          );
          acc.visibleSuppliers.push(requisitionSupplier.id);
          acc.suppliersData[requisitionSupplier.id] = {
            ...requisitionSupplier,
            supplier_full_name: supplier.full_name,
            supplier_email: supplier.email,
            supplier_emails: supplier.emails,
            supplier_phone: supplier.phone,
            supplier_rating: supplier.supplier_rating,
            status_id: requisitionSupplier.status_id
          };

          acc.suppliersHistory[requisitionSupplier.id] = createSupplierHistory(history);
          acc.suppliersEvaluations[requisitionSupplier.id] = evaluation;

          return acc;
        },
        {
          suppliers: [],
          suppliersData: {},
          suppliersHistory: {},
          suppliersEvaluations: {},
          visibleSuppliers: [],
          suppliersWithEquivalentCurrency: []
        }
      );

      return {
        ...state,
        ...suppliersState
      };
    }

    case TYPES.GET_PURCHASING_REQUISITION_SUPPLIER.SUCCESS:
      const { history, evaluation, id, supplier, ...requisitionSupplier } = payload;

      return {
        ...state,
        suppliersData: {
          ...state.suppliersData,
          [id]: {
            ...state.suppliersData[id],
            supplier_full_name: supplier.full_name,
            supplier_email: supplier.email,
            supplier_emails: supplier.emails,
            supplier_phone: supplier.phone,
            supplier_rating: supplier.supplier_rating,
            ...requisitionSupplier
          }
        },
        suppliersHistory: { ...state.suppliersHistory, [id]: createSupplierHistory(history) },
        suppliersEvaluations: { ...state.suppliersEvaluations, [id]: evaluation },
        suppliersWithEquivalentCurrency: createSuppliersWithEquivalentCurrency(payload, state)
      };

    case TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER_STATUS.SUCCESS:
    case TYPES.APPROVE_PURCHASING_REQUISITION.SUCCESS:
      const currentStatusIndex = supplierStatuses.findIndex(s => s === state.active.status?.label);
      const newStatusIndex = supplierStatuses.findIndex(s => s === payload.status?.label);

      return {
        ...state,
        suppliersWithEquivalentCurrency: createSuppliersWithEquivalentCurrency(payload, state),
        active:
          newStatusIndex > currentStatusIndex && payload.status?.label !== 'dlv'
            ? { ...state.active, status: payload.status }
            : state.active
      };

    case TYPES.SEND_PURCHASING_REQUISITIONS_EMAILS.SUCCESS:
      return {
        ...state,
        requisitionEmails: [...state.requisitionEmails, payload]
      };

    case TYPES.GET_PURCHASING_REQUISITIONS_EMAILS.SUCCESS:
      return {
        ...state,
        requisitionEmails: payload
      };

    case TYPES.LINK_PURCHASING_REQUISITIONS_EMAIL.SUCCESS:
      return {
        ...state,
        requisitionLinkedEmails: payload
      };

    case TYPES.GET_PURCHASING_REQUISITIONS_LINKED_EMAILS.SUCCESS:
      return {
        ...state,
        requisitionLinkedEmails: payload
      };

    case TYPES.DELETE_PURCHASING_REQUISITION_EMAIL_LINK.SUCCESS:
      return {
        ...state,
        requisitionLinkedEmails: state.requisitionLinkedEmails.filter(
          email => email.id !== payload.id
        )
      };

    case TYPES.SET_VISIBLE_ITEM_FIELDS:
      return {
        ...state,
        visibleItemFields: payload
      };

    case TYPES.TOGGLE_PURCHASING_ITEMS_SELECTION_DRAWER:
      return {
        ...state,
        isPurchasingItemsSelectionDrawerOpen: payload
      };

    case TYPES.TOGGLE_PURCHASING_COMMUNICATION_DRAWER:
      return {
        ...state,
        isCommunicationDrawerOpen: payload
      };

    case TYPES.TOGGLE_PMS_ITEMS_SELECTION_DRAWER:
      return {
        ...state,
        isPmsItemsSelectionDrawerOpen: payload
      };

    case TYPES.SET_ACTIVE_CATEGORY_ID:
      return {
        ...state,
        activeCategoryID: payload
      };

    case TYPES.SET_SUPPLIERS_VISIBILITY:
      return {
        ...state,
        visibleSuppliers: payload
      };

    case TYPES.TOGGLE_ALL_SUPPLIERS_VISIBILITY:
      return {
        ...state,
        visibleSuppliers: payload ? state.suppliers : []
      };

    case TYPES.TOGGLE_SUPPLIER_VISIBILITY:
      if (payload.visibility && !state.visibleSuppliers.find(s => s === payload.id)) {
        return { ...state, visibleSuppliers: [...state.visibleSuppliers, payload.id] };
      } else {
        return {
          ...state,
          visibleSuppliers: state.visibleSuppliers.filter(s => s !== payload.id)
        };
      }

    case TYPES.GET_PURCHASING_TABLE_CONFIGURATION.START:
    case TYPES.GET_PURCHASING_TABLE_CONFIGURATION.SUCCESS:
    case TYPES.EDIT_PURCHASING_TABLE_CONFIGURATION.SUCCESS:
      return {
        ...state,
        activeTableConfiguration: {
          id: payload?.id || 'default',
          settings: payload?.settings || [],
          name: payload?.name || 'Orca Default'
        }
      };

    case TYPES.DELETE_PURCHASING_TABLE_CONFIGURATION.SUCCESS:
      return {
        ...state,
        activeTableConfiguration: { id: 'default', settings: [], name: 'Orca Default' }
      };

    case TYPES.SET_REQUISITION_VESSEL:
      return {
        ...state,
        requisitionVessel: payload
      };

    case TYPES.SET_PREVENT_LOCATION_CHANGE:
      return {
        ...state,
        preventLocationChange: payload
      };

    case TYPES.TOGGLE_LAST_DELIVERED_TOOLTIP:
      return {
        ...state,
        lastDeliveredTooltip: payload
      };

    case TYPES.TOGGLE_REMARKS_AND_ATTACHMENTS:
      return {
        ...state,
        showRemarksAndAttachments: payload
      };

    case TYPES.TOGGLE_SUMMARY_VIEW:
      return {
        ...state,
        isSummaryViewActive: payload
      };

    case TYPES.SET_COLLAPSED_FINDINGS:
      return {
        ...state,
        collapsedFindings: payload
      };

    case TYPES.SET_IS_LAST_REQUISITION_LOADED:
      return {
        ...state,
        isLoadedFromPastRequisition: payload
      };

    case TYPES.SET_ITEM_COMMENTS_DRAWER_OPEN:
      return {
        ...state,
        itemCommentsDrawer: payload
      };

    case TYPES.SET_SUPPLIER_PO_DETAILS_DRAWER_OPEN:
      return {
        ...state,
        isSupplierPoDetailsDrawerOpen: payload
      };

    case TYPES.SET_SELECTED_ITEM_TO_BE_REPLACED:
      return {
        ...state,
        selectedItemToBeReplaced: payload
      };

    case TYPES.SET_ITEMS_TABLE_CONFIGURATION:
      return {
        ...state,
        itemsTableConfiguration: payload
      };

    case TYPES.SET_ITEMS_TABLE_COMPARISON_VIEW_ENABLED:
      return {
        ...state,
        itemsTableComparisonViewEnabled: payload
      };

    case `${unlinkFinding?.fulfilled}`:
      return {
        ...state,
        active: {
          ...state.active,
          findings: (state.active?.findings || [])?.filter(e => e.id !== payload?.id)
        }
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        isTemplate: payload.location.pathname.startsWith(paths.purchasing_requisition_templates)
          ? true
          : false
      };

    default:
      return state;
  }
};
export default reducer;
