import React, { useState, useMemo } from 'react';
import NumberInput from 'common/components/form/inputs/NumberInput';
import { Row, Col } from 'reactstrap';

import * as requisitionActions from 'common/components/purchasing/requisition/store/actions';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectActiveRequisitionID,
  selectOnBoardStatus,
  selectRequisitionIsForVessel,
  selectSupplierStatusLabel,
  selectSupplierDeliveryStatus
} from 'common/components/purchasing/requisition/store/selectors';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import CircledButton from 'common/components/buttons/CircledButton';
import PageLoader from 'common/components/general/PageLoader';
import { canEditFieldBasedOnStatus } from '../helpers';
import { reviewProcessHasSomeCompletedSteps } from 'common/components/review-process/store/selectors';

const DiscountFieldHeader = ({ field, categoryID, isPms }) => {
  const [value, setValue] = useState(null);
  const { supplierRequisitionID } = field;
  const [previousRequestedValue, setPreviousRequesteValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const activeID = useSelector(selectActiveRequisitionID);
  const isForVessel = useSelector(selectRequisitionIsForVessel);
  const statuses = useSelector(state =>
    selectListOptionsFromStore(state, 'purchasing-requisition-statuses')
  );
  const onBoardStatus = useSelector(selectOnBoardStatus);

  const supplierStatusLabel = useSelector(state =>
    selectSupplierStatusLabel(state, supplierRequisitionID)
  );
  const isOnBoard = useSelector(state => state.isOnBoard);
  const supplierDeliveryStatus = useSelector(state =>
    selectSupplierDeliveryStatus(state, supplierRequisitionID)
  );

  const isNotEditableAfterReviewProcessApproval = useSelector(state =>
    reviewProcessHasSomeCompletedSteps(state, activeID)
  );

  const canEditThisField = useMemo(
    () =>
      canEditFieldBasedOnStatus({
        supplierStatusLabel,
        statuses,
        onBoardStatus,
        isOnBoard,
        supplierDeliveryStatus,
        isForVessel,
        key: field.key
      }),
    [
      supplierStatusLabel,
      statuses,
      onBoardStatus,
      isOnBoard,
      supplierDeliveryStatus,
      isForVessel,
      field.key
    ]
  );
  const [applyDiscountToAllItems] = useActions([requisitionActions.applyDiscountToAllItems]);

  const handleDiscount = async () => {
    if (!value || value === previousRequestedValue) return;

    setIsLoading(true);

    try {
      await applyDiscountToAllItems({
        id: activeID,
        supplierID: field.supplierRequisitionID,
        discount_percentage: value,
        item_type: [isPms ? 'spare_part' : 'store'],
        category_id: categoryID
      });

      setPreviousRequesteValue(value);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return (
    <>
      {canEditThisField ? (
        <Row className="d-flex align-items-center cmt-4 flex-nowrap g-0">
          <Col xs={10} className="cps-4 cpe-4">
            <NumberInput
              className="discount-input mb-0"
              value={value}
              onChange={e => setValue(e.target.value)}
              disabled={isNotEditableAfterReviewProcessApproval}
            />
          </Col>
          <Col className="px-0" xs={2}>
            {isNotEditableAfterReviewProcessApproval ? null : (
              <CircledButton
                type={'reset'}
                style={{ width: 15, height: 15 }}
                svgStyle={{ width: 9, height: 9 }}
                className="d-inline-flex p-0"
                svgWrapperClassName="bg-primary"
                onClick={handleDiscount}
              />
            )}
          </Col>
        </Row>
      ) : null}
      <PageLoader isLoading={isLoading} />
    </>
  );
};

export default DiscountFieldHeader;
