import {
  getColumnVisibitity,
  getComparedViewColumnVisibitity,
  importColumnConfig
} from '../table-configuration/helpers';
import { ITEMS_CONFIGURATION_KEYS, SUPPLIER_FIELDS } from './config';

import _isNumber from 'lodash/isNumber';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import config, { styles, FIELDS } from './config';
import permissions from '@/common/utils/permissions/constants';

const getTableFieldVisibility = (
  { tableConfigurationSettings, itemsTableConfiguration, comparisonViewIsEnabled },
  field
) => {
  // Filter fields based on table config visibility
  if (tableConfigurationSettings?.length) {
    return tableConfigurationSettings.find(f => f.key === field.key)?.visible;
  } else {
    // When in Comparison view, we filter fields with showInComparisonView = true
    if (comparisonViewIsEnabled) return getComparedViewColumnVisibitity(field);

    // When no table config is set, we filter fields based on the selected ITEMS_CONFIGURATION_KEYS
    if (
      !itemsTableConfiguration.length ||
      itemsTableConfiguration.includes(ITEMS_CONFIGURATION_KEYS.all)
    ) {
      return true;
    } else if (itemsTableConfiguration.length) {
      return getColumnVisibitity(field, itemsTableConfiguration);
    }

    return true;
  }
};

const getSupplierFieldIndex = (tableConfigurationSettings, fields) => {
  // Find the index of the first supplier field if there is a config get it from there
  if (tableConfigurationSettings?.length) {
    return tableConfigurationSettings.find(f => f.key === 'suppliers').sort_index;
  } else {
    return fields.findIndex(field => SUPPLIER_FIELDS[field.key]);
  }
};

export const parseCurrencyEquivalentFields = (
  fields,
  { suppliersWithEquivalentCurrency },
  { activeTableConfigurationSettings, itemsTableConfiguration }
) => {
  const itemFields = fields.filter(
    field =>
      !field.supplierRequisitionID ||
      (field.supplierRequisitionID && !field.equivalentCurrency) ||
      (field.supplierRequisitionID &&
        field.equivalentCurrency &&
        suppliersWithEquivalentCurrency.includes(+field.supplierRequisitionID))
  );

  if (
    !activeTableConfigurationSettings?.length &&
    itemsTableConfiguration.includes(ITEMS_CONFIGURATION_KEYS.usd)
  ) {
    return itemFields.filter(field => {
      // Display only the USD fields
      const supplierHasEquivalentCurrency = suppliersWithEquivalentCurrency.includes(
        +field.supplierRequisitionID
      );

      return field.supplierRequisitionID && field.currency
        ? (field.equivalentCurrency && supplierHasEquivalentCurrency) ||
            (!field.equivalentCurrency && !supplierHasEquivalentCurrency)
        : field;
    });
  }

  return itemFields;
};

export const parseFieldsPerStatus = (
  { onBoardStatus, status },
  { visibleItemSuppliers, suppliersData },
  { tableConfigurationSettings, itemsTableConfiguration, comparisonViewIsEnabled },
  { isOnBoard, isOutOfList, isForVessel }
) => {
  const { columns, supplierColumns } = tableConfigurationSettings?.length
    ? importColumnConfig(tableConfigurationSettings)
    : { columns: [], supplierColumns: [] };

  const requistionStatusForConfig =
    isOnBoard || onBoardStatus !== 'submitted'
      ? status === 'dlv' || status === 'ev'
        ? status
        : null
      : status;

  const configFields = config({
    onBoardStatus,
    status: requistionStatusForConfig,
    isOnBoard,
    isForVessel
  });

  const itemFields = _get(
    configFields,
    isOutOfList ? 'out_of_the_list_items' : 'items',
    configFields
  ).filter(
    field =>
      getTableFieldVisibility(
        { tableConfigurationSettings, itemsTableConfiguration, comparisonViewIsEnabled },
        field
      ) || isOutOfList
  );

  const filteredItemFields = itemFields
    ?.filter(field => !SUPPLIER_FIELDS[field.key])
    .map((f, i) => ({
      ...f,
      sort_index: columns?.length ? columns.find(c => c.key === f.key)?.sort_index : i
    })); // Get all non-supplier visible fields -> if there is config, map them with sort_index

  const index = getSupplierFieldIndex(tableConfigurationSettings, itemFields);

  if (!isOutOfList && status !== 'rqn') {
    let supplierFields = [];

    Object.keys(visibleItemSuppliers).forEach(supplierRequisitionID => {
      // Create the supplier field columns for all valid suppliers (based on status) -> filter by config visibility and
      //add sort_index from config as well
      let supplierStatus = suppliersData[supplierRequisitionID]?.status?.label;

      const suppplierConfigFields = config({
        onBoardStatus,
        status: supplierStatus,
        isOnBoard,
        isForVessel
      });

      const filteredSupplierFields = [
        ...suppplierConfigFields.filter(
          field =>
            SUPPLIER_FIELDS[field.key] &&
            getTableFieldVisibility(
              { tableConfigurationSettings, itemsTableConfiguration, comparisonViewIsEnabled },
              field
            )
        )
      ];

      supplierFields = [
        ...supplierFields,
        ...filteredSupplierFields.map((field, i) => ({
          ...field,
          supplierRequisitionID,
          supplier_sort_index: visibleItemSuppliers[supplierRequisitionID].supplier_sort_index,
          sort_index: supplierColumns?.length
            ? supplierColumns.find(c => c.key === field.key)?.sort_index
            : i
        }))
      ];
    });

    const itemFieldsOrdered = _sortBy(filteredItemFields, ['sort_index']);
    const supplierFieldsOrdered = _sortBy(supplierFields, ['supplier_sort_index', 'sort_index']);

    // Return an array of: [basic fields, all supplier fields, rest basic fields]
    return [
      ...itemFieldsOrdered.filter(item => item.sort_index < index),
      ...supplierFieldsOrdered,
      ...itemFieldsOrdered.filter(item => item.sort_index >= index)
    ];
  }

  return filteredItemFields;
};

export const isSupplierField = field => _isNumber(field?.supplier_sort_index);

export const isPreviousSupplierField = (fields, fieldIndex) =>
  _isNumber(fields?.[fieldIndex - 1]?.supplier_sort_index);

export const isNextSupplierField = (fields, fieldIndex) =>
  _isNumber(fields?.[fieldIndex + 1]?.supplier_sort_index);

export const isFirstSupplierField = (fields, fieldIndex, field) =>
  isSupplierField(field) &&
  (!isPreviousSupplierField(fields, fieldIndex) ||
    field?.supplier_sort_index > fields?.[fieldIndex - 1]?.supplier_sort_index);

export const getItemClassNames = (fieldsInfo, fieldsProperties) => {
  const { field, fieldIndex, fields } = fieldsInfo;
  const { isHeader, isFooter, isOnBoard, isInDeliveryReport } = fieldsProperties;

  const classNames = field?.className ? [field.className] : [];

  classNames.push('requisition-item');

  if (isHeader && field?.headerClassName) classNames.push(field.headerClassName);
  if (isFooter && field?.footerClassName) classNames.push(field.footerClassName);
  if (field?.supplierRequisitionID && !isOnBoard && !isInDeliveryReport) {
    classNames.push('requisition-supplier-item');

    classNames.push(`supplier-index-${(field.supplier_sort_index + 1) % 10 || 10}`);

    if (isHeader) classNames.push('header-field');
    else if (isFooter) classNames.push('footer-field');
    else classNames.push('body-field');
  }

  if (
    field.key === FIELDS.total_proposed_quantity.key ||
    field.key === FIELDS.total_approved_quantity.key ||
    field.key === FIELDS.total_delivered_quantity.key ||
    isFirstSupplierCell(fields, fieldIndex, field)
  ) {
    classNames.push(styles.indexes.first, styles.separated.left);
  }

  if (!isSupplierField(field) && isNextSupplierField(fields, fieldIndex)) {
    //This is to find the last normal field by checking if the next field is a supplier field
    classNames.push(styles.indexes.last, styles.separated.right);
  }

  // This is the last field of each supplier
  if (
    field.key === FIELDS.total_proposed_quantity.key ||
    field.key === FIELDS.total_approved_quantity.key ||
    field.key === FIELDS.total_delivered_quantity.key ||
    (isSupplierField(field) &&
      (!isNextSupplierField(fields, fieldIndex) ||
        (isNextSupplierField(fields, fieldIndex) &&
          field?.supplier_sort_index < fields[fieldIndex + 1]?.supplier_sort_index)))
  ) {
    classNames.push(styles.indexes.last, styles.separated.right);
  }

  if (field?.isNew) {
    classNames.push('is-new');
  }

  return classNames.join(' ');
};

export const getInitialValueFrom = key => {
  switch (key) {
    // case 'available_packaging_id':
    //   return { key: 'requested_packaging_id', isSupplierField: false };

    default:
      return null;
  }
};

export const createSuppliersWithEquivalentCurrency = (payload, state) => {
  if (
    payload?.system_currency_label &&
    payload?.quotation_currency?.label &&
    payload?.quotation_currency?.label !== payload?.system_currency_label
  ) {
    if (!state.suppliersWithEquivalentCurrency.find(e => e === payload?.id)) {
      return [...state.suppliersWithEquivalentCurrency, payload?.id];
    } else {
      return state.suppliersWithEquivalentCurrency;
    }
  } else {
    return state.suppliersWithEquivalentCurrency.filter(e => e !== payload?.id);
  }
};

export const updateSuppliersWithEquivalentCurrency = (
  requisitionSupplier,
  suppliersWithEquivalentCurrency
) => {
  if (
    requisitionSupplier?.quotation_currency?.label &&
    requisitionSupplier.system_currency_label &&
    requisitionSupplier.system_currency_label !== requisitionSupplier?.quotation_currency?.label
  ) {
    return suppliersWithEquivalentCurrency.push(requisitionSupplier.id);
  }
};

export const getSupplierStatusExplanatoryText = (statuses, status, hasRequisitionVessel) => {
  // Below we add +1 in all statuses --- the case with +2 is because we have an APR status which is not displayed in suppliers
  const label = statuses?.find(s => s?.sort_index === status?.sort_index)?.label;

  switch (label) {
    case 'draft':
      return 'Requisition is still in Draft state.';

    case 'rfq':
      return 'Preparing to send RFQ to the supplier. Please fill in Revised Quantities in REV QTY column and export Excel with Items.';

    case 'qtn':
      return 'Waiting for Quotation from the supplier. You can upload the “filled” Excel file, or you can fill in all info manually in the table below.';

    case 'pr':
      return 'Preparing Proposed Order for approval. Please fill in the Proposed Quantity and start the approval process, or Reject the offer.';

    case 'po':
      return 'Approval Step. Please fill in the Approved QTY column, approve your step (top right), and send the order to the Supplier.';

    case 'dlv':
      return hasRequisitionVessel
        ? 'Order is placed. Waiting for the Delivery Report from Vessel.'
        : 'Order is placed.';

    case 'ev':
      return 'Evaluate the supplier.';

    case 'comp':
      return 'This requisition is completed.';

    default:
      return 'This requisition is submitted. Please fill in Revised Quantities in “REV QTY” column and press “Assign Supplier” in order to proceed.';
  }
};

const canEditDeliveryFieldBasedOnSupplierDeliveryStatus = (
  isOnBoard,
  supplierDeliveryStatus,
  isForVessel
) => {
  if (isOnBoard) return supplierDeliveryStatus !== 'submitted';

  return !isForVessel && supplierDeliveryStatus !== 'submitted';
};

export const canEditFieldBasedOnStatus = ({
  key,
  supplierStatusLabel,
  statuses,
  onBoardStatus,
  isOnBoard,
  supplierDeliveryStatus,
  isForVessel
}) => {
  if (key === 'delivered_quantity') {
    return canEditDeliveryFieldBasedOnSupplierDeliveryStatus(
      isOnBoard,
      supplierDeliveryStatus,
      isForVessel
    );
  }

  return onBoardStatus === 'submitted' && statuses?.length
    ? !(statuses.find(st => st.label === supplierStatusLabel)?.uneditable_fields || []).some(
        field => field.name === key || field.name === `${key}_id`
      )
    : false;
};

export const constructOutOfListItemId = (outOfListItem, isPms) => {
  return isPms ? `spare_part_${outOfListItem}` : outOfListItem;
};

export const isFirstSupplierCell = (fields, fieldIndex, field) => {
  return (
    (isFirstSupplierField(fields, fieldIndex, field) ||
      (!isSupplierField(field) && isPreviousSupplierField(fields, fieldIndex))) &&
    (field?.supplier_sort_index || field?.supplier_sort_index === 0)
  );
};

export const findWidthPerSupplier = (fields = [], supplierSortIndex) => {
  return fields
    .filter(field => field?.supplier_sort_index === supplierSortIndex)
    .reduce((acc, curr) => acc + (curr?.maxWidth || 0), 0);
};
