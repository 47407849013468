import Slider from '@/ts-common/components/form/inputs/Slider';
import NumberInput from '@/ts-common/components/form/inputs/NumberInput';

import { Col, Row } from 'reactstrap';
import { scaleNumberIsAllowed } from 'common/utils/numbers';
import { strToNumber } from 'common/utils/numbers';
import { FC, ReactNode } from 'react';
import { ReactSliderProps } from 'react-slider';

type ScaleNumberInputProps = {
  label?: ReactNode;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  disabled?: boolean;
  onChange: (v: string | number | null) => void;
  sliderProps: ReactSliderProps;
  widths?: number[];
};

const ScaleNumberInput: FC<ScaleNumberInputProps> = ({
  label,
  min,
  max,
  step = 1,
  value,
  disabled,
  onChange,
  sliderProps = {},
  widths = [9, 3]
}) => {
  const onInputChange = (val: string | number | null) => {
    if (scaleNumberIsAllowed(val, { min: min, max: max, step: step })) {
      onChange(strToNumber(val));
    }
  };

  return (
    <Row className="d-flex align-items-center w-100p">
      <Col xs={widths[0]} className="pe-4">
        <Slider
          label={label}
          min={min}
          max={max}
          step={step}
          value={value}
          disabled={disabled}
          onAfterChange={onInputChange}
          className={'w-100p'}
          {...sliderProps}
        />
      </Col>
      <Col xs={widths[1]} className="pe-4">
        <NumberInput
          className="mb-0"
          innerClassName="text-center"
          value={value}
          onChange={e => onInputChange(e.target.value)}
          disabled={disabled}
          isAllowed={value =>
            scaleNumberIsAllowed(value.floatValue, { min: min, max: max, step: step }) ||
            (max &&
              max
                .toString()
                .startsWith(
                  value.value
                )) /* Example: step = 2, max = 10 - this fix allows typing the value 10  */ ||
            value.floatValue === undefined
          }
        />
      </Col>
    </Row>
  );
};

export default ScaleNumberInput;
