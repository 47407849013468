import { FC, PropsWithChildren } from 'react';

type EntityLabelProps = PropsWithChildren<{
  type: 'department' | 'crew-department' | 'company-type' | 'status';
  color?: string;
  bullet?: boolean;
  className?: string;
  innerClassName?: string;
  id?: string;
}>;

const EntityLabel: FC<EntityLabelProps> = ({
  type,
  color = '',
  bullet,
  className,
  innerClassName,
  children,
  id,
  ...rest
}) => {
  return (
    <div
      className={`entity-label entity-label--${type} ${
        color ? `entity-label--${type}-${color}` : ''
      } ${bullet ? `entity-label--bullet` : ''} ${className || ''}`}
      id={id ? id : ''}
      {...rest}
    >
      {!bullet ? (
        <div className={`entity-label__inner ${innerClassName || 'text-truncate'}`}>{children}</div>
      ) : null}
    </div>
  );
};

export default EntityLabel;
