import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const Mga = React.lazy(() => retry(() => import('@/views/accounting/mga/index.jsx')));

const routes = [
  {
    path: `${paths.mga}/:view(overview|accounts|actions|inventory|reports)/:vesselID?/:periodID?`,
    component: Mga,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.accounting.mga.view],
    documentTitle: 'MGA'
  }
];
export default routes;
