import { useQuery } from '@tanstack/react-query';
import { getDefaultOptions } from '@/ts-common/components/form/inputs/select/api/api';
import { OptionsRequestParams } from '@/ts-common/components/form/inputs/select/types';
import { constructKeyFromPath } from '../select-helpers';
import { listsKey } from './query-keys';

export const useDefaultOptionsQuery = (options?: OptionsRequestParams) => {
  const key = constructKeyFromPath(options?.path);

  return useQuery({
    queryKey: [listsKey, key, options],
    queryFn: () => getDefaultOptions(options),
    enabled: options?.enabled !== undefined ? options.enabled : true,
    retry: false,
    staleTime: 5 * 60 * 1000
  });
};
