import { selectListOptionsFromStore } from 'store/lists/selectors';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import Select from 'common/components/form/inputs/Select';
import SupplierFieldComparisonDetails from '../components/SupplierFieldComparisonDetails';

const BasicListField = ({
  value,
  onChange,
  onAutoSave,
  disabled,
  list,
  fieldKey,
  fieldShowInComparisonView,
  hidden,
  ...rest
}) => {
  const options = useSelector(state => selectListOptionsFromStore(state, list));

  const handleOnChange = useCallback(v => {
    onChange(v);
    if (onAutoSave) onAutoSave({ [fieldKey]: v });
  }, []);

  return (
    <>
      <Select
        className={`mb-0 ${hidden ? 'opacity-0' : ''}`}
        value={value}
        isDisabled={disabled || hidden}
        onChange={handleOnChange}
        isAsync={false}
        options={options}
        getOptionLabel={option => option.name}
        placeholder=""
        {...rest}
      />
      {fieldShowInComparisonView ? (
        <SupplierFieldComparisonDetails
          fieldKey={fieldKey}
          supplierRequisitionID={rest.supplierRequisitionID}
          itemID={rest.itemID}
        />
      ) : null}
    </>
  );
};

export default BasicListField;
