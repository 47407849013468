import { selectScaleLabel, selectDefaultScaleLabels } from '@/store/lists/selectors-ts';
import { useAppSelector } from '@/store/hooks';
import { FC } from 'react';

export const Bullet = ({ color }: { color?: string }) => (
  <div className={`me-1 scale-rating-bullet scale-rating-bullet--${color}`} />
);

export const ScaleRatingValue = ({ value }: { value: number }) => {
  const scale = useAppSelector(state => selectScaleLabel(state, value));

  return (
    <div data-cy="scale-rating-value" className="scale-ratings d-flex align-items-center">
      <Bullet color={scale?.color} />
      <div className="fw-bold fs-14 text-primary me-1">{value}</div>
      <div className="fw-medium text-primary">{scale?.name}</div>
    </div>
  );
};

type ScaleRatingsProps = {
  hasBullet?: boolean;
  showNumber?: boolean;
};

const ScaleRatings: FC<ScaleRatingsProps> = ({ hasBullet = false, showNumber = true }) => {
  const scaleLabels = useAppSelector(selectDefaultScaleLabels);

  return (
    <div data-cy="scale-rating" className="d-flex align-items-center">
      {(scaleLabels || [])?.map((info, i) => (
        <div
          key={i}
          className="scale-ratings d-flex align-items-center me-4 text-violet fw-normal fs-12"
        >
          {hasBullet ? <Bullet color={info.color} /> : null}
          {showNumber ? <span className="fw-bold me-1">{i + 1}</span> : null}
          {info.name}
        </div>
      ))}
    </div>
  );
};

export default ScaleRatings;
