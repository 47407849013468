import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import CircledButton from '@/common/components/buttons/CircledButton';
import {
  selectActiveRequisitionID,
  selectOnBoardStatus,
  selectRequisitionIsForVessel,
  selectSupplierDeliveryStatus,
  selectSupplierStatusLabel
} from '@/common/components/purchasing/requisition/store/selectors';
import { reviewProcessHasSomeCompletedSteps } from '@/common/components/review-process/store/selectors';
import { getPurchasingRequisitionSuppliers } from '@/common/components/purchasing/requisition/store/actions';
import { selectApprovedQuantityFieldIsDisabled } from '@/common/components/purchasing/requisition/store/selectors-ts';
import permissions from '@/common/utils/permissions/constants';
import Tooltip from '@/ts-common/components/general/Tooltip.tsx';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID.ts';

import { isAuthorized } from '@/utils/permissions/authorize';
import { useAppSelector } from '@/store/hooks';
import { selectListOptionsFromStore } from '@/store/lists/selectors';
import { selectAccount } from '@/store/account/selectors';

import {
  fillQuantityFromExistings,
  fillRevQuantityFromReQ
} from '@/common/components/purchasing/requisition/store/actions';
import { canEditFieldBasedOnStatus } from '../helpers';
import AuthCheck from '@/components/permissions/AuthCheck';
import { addFieldProperPermissions } from '@/common/components/purchasing/requisition/categories/items/helpers-ts';

const getTooltipMessage = fieldKey => {
  switch (fieldKey) {
    case 'available_quantity':
      return 'Fill in quantities from REV QTY';

    case 'proposed_quantity':
      return 'Fill in quantities from AVBL QTY';

    case 'approved_quantity':
      return 'Fill in quantities from PROPOSED QTY';
    case 'delivered_quantity':
      return 'Fill in quantities from ORDERED QTY';
    case 'revised_quantity':
      return 'Fill in quantities from REQ QTY';
    default:
      return '';
  }
};

const AutoFillButton = ({ categoryID, field, isPms, isOnBoard }) => {
  const dispatch = useDispatch();
  const { tooltipID, avoidRender } = useTooltipID('autofill-button-tooltip');

  const activeRequisitionId = useAppSelector(selectActiveRequisitionID);
  const statuses = useAppSelector(state =>
    selectListOptionsFromStore(state, 'purchasing-requisition-statuses')
  );
  const supplierStatusLabel = useAppSelector(state =>
    selectSupplierStatusLabel(state, field?.supplierRequisitionID)
  );
  const supplierDeliveryStatus = useAppSelector(state =>
    selectSupplierDeliveryStatus(state, field?.supplierRequisitionID)
  );
  const onBoardStatus = useAppSelector(selectOnBoardStatus);
  const isForVessel = useAppSelector(selectRequisitionIsForVessel);
  const account = useAppSelector(selectAccount);
  const toolTipMessage = getTooltipMessage(field.key);

  const isNotEditableAfterReviewProcessApproval = useAppSelector(state =>
    reviewProcessHasSomeCompletedSteps(state, activeRequisitionId)
  );
  const approvedQuantityFieldIsDisabled = useAppSelector(selectApprovedQuantityFieldIsDisabled);
  const canEditRevisedButton = isAuthorized(account, [
    permissions.office.purchasing.requisitions.items.update_revised_quantity
  ]);

  const canEditThisField = useMemo(
    () =>
      canEditFieldBasedOnStatus({
        isOnBoard,
        supplierDeliveryStatus,
        isForVessel,
        statuses,
        supplierStatusLabel,
        onBoardStatus,
        key: field.key
      }),
    [
      field.key,
      isOnBoard,
      supplierDeliveryStatus,
      isForVessel,
      supplierStatusLabel,
      statuses,
      onBoardStatus
    ]
  );

  const handleFill = async () => {
    try {
      const params = {
        id: activeRequisitionId,
        field: field.key,
        item_type: isPms ? ['spare_part'] : ['store'],
        purchasing_category_id: categoryID
      };

      if (field.key === 'revised_quantity') {
        await dispatch(fillRevQuantityFromReQ(params));
      } else {
        const res = await dispatch(
          fillQuantityFromExistings({
            ...params,
            supplierID: field.supplierRequisitionID
          })
        );

        if (field.key === 'delivered_quantity' && !isForVessel && res) {
          await dispatch(getPurchasingRequisitionSuppliers({ id: activeRequisitionId }));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  if (avoidRender || !canEditThisField) return null;
  if (
    (field.key === 'approved_quantity' && (isOnBoard || approvedQuantityFieldIsDisabled)) ||
    (field.key !== 'delivered_quantity' && isNotEditableAfterReviewProcessApproval) ||
    (field.key === 'revised_quantity' && !canEditRevisedButton)
  )
    return null;

  return (
    <>
      <AuthCheck permissions={addFieldProperPermissions(field.key)}>
        <div
          id={tooltipID}
          className="d-flex align-itmes-center justify-content-center flex-1"
          data-cy="field-autofill-button"
        >
          <CircledButton
            type="reset"
            svgStyle={{ width: 10, height: 10 }}
            style={{ width: 14, height: 14 }}
            svgWrapperClassName="bg-white"
            svgClassName="text-primary"
            className="position-relative"
            onClick={handleFill}
          />
        </div>
        <Tooltip target={tooltipID}>{toolTipMessage}</Tooltip>
      </AuthCheck>
    </>
  );
};

export default AutoFillButton;
