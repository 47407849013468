import permissions from '@/common/utils/permissions/constants';

export const addFieldProperPermissions = (key: string) => {
  switch (key) {
    case 'available_quantity':
    case 'discount_percentage':
      return [permissions.office.purchasing.requisitions.items.edit_quotation];

    default:
      return [];
  }
};
