import { number } from 'prop-types';

import { WinguEmail } from 'common/entities/emails/WinguEmailTypes';
import CircledButton from 'common/components/buttons/CircledButton';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  deletePurchasingRequisitionEmailLink,
  getPurchasingRequisitionLinkedEmailTempUrl
} from '../../../store/actions';
import SvgRender from 'common/components/general/SvgRender';
import goToPage from 'common/assets/svg/actions/go-to-page.svg';

const LinkedEmail = ({ email, index }) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      dispatch(deletePurchasingRequisitionEmailLink({ id: email.id }));
    } catch (error) {
      console.error(error);
    }
  };

  const openEmailInANewTab = async emailId => {
    if (!emailId) return;

    try {
      const res = await dispatch(getPurchasingRequisitionLinkedEmailTempUrl({ email_id: emailId }));

      if (!res?.url) return;

      window.open(res?.url, '_blank');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="d-flex align-items-start cmb-2">
      <div
        className={`bg-light-gray py-1 ps-1 rounded-lg fs-12 w-100p ${index === 0 ? 'mt-1' : ''}`}
      >
        <div className="text-moody-blue fw-medium">
          {email.status.label === 'po' ? 'PO' : email.status.name} |{' '}
          {moment(email.created_at).format('DD MMM YYYY')}
        </div>

        <span className="text-primary fw-medium">{email.email?.subject || ''}</span>

        <SvgRender
          className="text-primary ms-1 cursor-pointer"
          onClick={() => openEmailInANewTab(email?.id)}
          src={goToPage}
          style={{ width: 12, height: 12 }}
        />
      </div>

      <CircledButton
        onClick={handleDelete}
        className="w-auto mt-1 ms-1"
        type="remove"
        style={{ width: 15, height: 15 }}
        svgStyle={{ width: 6, height: 1 }}
      />
    </div>
  );
};

export default LinkedEmail;

LinkedEmail.propTypes = {
  index: number.isRequired,
  email: WinguEmail.isRequired
};
