import { useAppSelector } from '@/store/hooks';
import {
  selectApprovedQuantityFieldIsDisabled,
  supplierAvailableQuantityIsZero
} from '@/common/components/purchasing/requisition/store/selectors-ts';

import DifferenceNumberField from './DifferenceNumberField';

const ApprovedQuantityField = ({ disabled, ...rest }) => {
  const availableQuantityIsZero = useAppSelector(state =>
    supplierAvailableQuantityIsZero(state, rest.itemID, rest.supplierRequisitionID)
  );
  const approvedQuantityFieldIsDisabled = useAppSelector(selectApprovedQuantityFieldIsDisabled);

  return (
    <DifferenceNumberField
      {...rest}
      disabled={disabled || approvedQuantityFieldIsDisabled || availableQuantityIsZero}
    />
  );
};

export default ApprovedQuantityField;
